body {
  margin: 0;
  font-family:Georgia, Times, "Times New Roman", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
  color: #593C8F;
 font-size: 25px;
 font-family:'brittany-signature-regular';
 font-weight: bold;
 padding-left: 100px;
 word-wrap: break-word;
  height: 7rem;
 }